import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { importProvidersFrom } from '@angular/core';
import { bootstrapApplication, BrowserModule } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter, Router, withComponentInputBinding } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import {
  BudgetEffects,
  CharityEffects,
  DonationEffects,
  ItemEffects,
  JourneysEffects,
  OnboardingEffects,
  PageEffects,
  PartnerEffects,
  provideCoreProviders,
  reducerToken,
  StoreEffects,
  TruckEffects,
  UsersEffects,
  ZoneEffects,
} from '@core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { AuthenticationService, authRoutes, provideRsplAuthProviders } from '@rspl-auth';
import { provideRsplUIProviders } from '@rspl-ui';
import { provideRsplVersion } from '@rspl-version';
import { LaddaModule } from 'angular2-ladda';
import { firstValueFrom } from 'rxjs';

import { AppComponent } from './app/app.component';
import { appRoutes } from './app/app.routes';
import { environment } from './environments/environment';

bootstrapApplication(AppComponent, {
  providers: [
    provideHttpClient(withInterceptorsFromDi()),
    provideCoreProviders(environment),
    provideRsplUIProviders(),
    provideRsplVersion({ currentHash: '{{POST_BUILD_ENTERS_HASH_HERE}}' }),
    provideRouter(localStorage.getItem('rspl-user') ? appRoutes(environment) : authRoutes, withComponentInputBinding()),
    provideRsplAuthProviders(),
    importProvidersFrom(
      BrowserModule,
      ServiceWorkerModule.register('ngsw-worker.js', {
        enabled: environment.production,
      }),
      EffectsModule.forRoot([
        PageEffects,
        ZoneEffects,
        DonationEffects,
        TruckEffects,
        OnboardingEffects,
        CharityEffects,
        StoreEffects,
        UsersEffects,
        PartnerEffects,
        JourneysEffects,
        ItemEffects,
        BudgetEffects,
      ]),
      StoreModule.forRoot(reducerToken),
      StoreDevtoolsModule.instrument({
        maxAge: 25, // Retains last 25 states
        logOnly: environment.production, // Restrict extension to log-only mode
        connectInZone: true,
      }),
      LaddaModule.forRoot({
        style: 'zoom-in',
      }),
    ),
    provideAnimations(),
  ],
})
  .then((appRef) => {
    const authService = appRef.injector.get(AuthenticationService);
    const router = appRef.injector.get(Router);

    firstValueFrom(authService.isLoggedIn$).then((isLoggedIn) => {
      const routes = isLoggedIn ? appRoutes(environment) : authRoutes;
      router.resetConfig(routes); // ✅ Update the router dynamically
    });

    authService.isLoggedIn$.subscribe((isLoggedIn) => {
      const newRoutes = isLoggedIn ? appRoutes(environment) : authRoutes;
      router.resetConfig(newRoutes);
    });
  })
  .catch((err) => console.error(err));
